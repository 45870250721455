<template>
  <v-container fluid class="mt-4 ml-2 pr-6">
    <!--  -->
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title class="text-subtitle-1">
            Sistemas web
            <v-spacer></v-spacer>
            
            <v-btn
		          color="orange"
		          dark
		          class="mr-2"
		          @click="dialogArchivos.estatus = true"
		          small
		          tile
		        >
		          <v-icon left small>mdi-plus</v-icon>
		          Cat. Archivos
		        </v-btn>

            <v-btn color="primary" @click="initialize()" small tile>
              <v-icon small left>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  filled
                  single-line
                  label="Búscar"
                  append-icon="mdi-magnify"
                  hide-details
                  v-model="buscar"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- REQUISICIONES -->
            <v-data-table
              :headers="headers"
              :items="escuelas"
              dense
              class="elevation-0 mt-4"
              :search="buscar"
            >
            	<!-- Datos dinámicos de las tablas -->
				      <template v-slot:item.actions="{ item }">
		            <v-btn
		              color="primary"
		              dark
		              x-small
		              tile
		              @click="deleted( item )"
		              class="mr-2"
		            >
		              <!-- v-if="item.idcontador == 0" -->
		              <v-icon small>
		                mdi-delete
		              </v-icon>
		            </v-btn>

		            <v-btn
		              color="orange"
		              dark
		              x-small
		              tile
		              @click="editarEscuela(item)"
		            >
		              <v-icon small>
		               	mdi-pencil
		              </v-icon>
		            </v-btn>

				      </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Ver formulario -->
    <CatEscuelas    
      v-if="dialogArchivos.estatus"    
      :dialogArchivos="dialogArchivos" 
      @initialize2="initialize"
    />

    <!-- Ver formulario -->
    <EditarSistema    
      v-if="dialogEditarEscuela.estatus"    
      :dialogEditarEscuela="dialogEditarEscuela" 
      :escuela="escuela" 
      @initialize2="initialize"
    />

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />

  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";

import { saveAs } from 'file-saver';

import CatEscuelas   from "@/views/academico/sistemaweb/CatEscuelas.vue";
import EditarSistema from "@/views/academico/sistemaweb/EditarSistema.vue";


// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  components: {
    Alerta,
    carga,
    CatEscuelas,
    EditarSistema
  },

  mixins: [validarErrores, funcionesExcel],

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    empleados:[],
    empleado:null,

    archivosZip:[],

    dialog: false,
    dialogDelete: false,
    dialogVer: false,

    pdf: null,
		dialogPDF: false,
    
    buscar:'',
    headers: [
      { text: "ID"             , value: "idescuelas", sortable: false },
      { text: "Escuela"        , value: "escuela"              },
      { text: "Actions"        , value: "actions"              },
    ],

    escuelas:[],

    dialogArchivos:{
    	estatus: false,
    },

    archivos: [],
    archivo:null,

    dialogAddArchivo: false,
    usuarioSeleccionado: null,

    archivos:null,
    vistaPrevia: null,
    file: '',
    dragging: false,
    dialgImagen:false,
		url_servidor: '',
		fullscreen: false,
		pdf:null,
		dialogPDF: false,
		
		imagen: null,
		extension:'',
		archivoSeleccionado: null,

		dialogEditarEscuela:{
			estatus: false,
		}

  }),

  watch:{

  	empleado( value ){
  		if( value ){ this.initialize() }
  	}

  },

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
  },

  async created() {
  	this.url_servidor = axios.defaults.baseURL + "archivos_empleados/";

    await this.initialize();
  },

  methods: {

  	initialize(){
      this.dialogArchivos.estatus      = false
      this.dialogEditarEscuela.estatus = false
      this.cargar = true;

      this.escuelas = []
      return this.$http.get('escuela.get').then(response=>{

        if( [241,501].includes( this.getdatosUsuario.iderp ) ){
      	  this.escuelas = response.data.escuelasAll.filter( el => el.idescuelas == 11)
        }else{
          this.escuelas = response.data.escuelasAll

        }

      	this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
  	},

    deleted ( value ){
    	this.cargar = true

    	value.deleted = 1 
      return this.$http.put('escuela.update/' + value.idescuelas, value ).then(response=>{
      	this.validarSuccess('Datos cargados correctamente')
      	this.initialize()
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    editarEscuela ( value ){
    	this.escuela = value
    	this.dialogEditarEscuela.estatus = true

    }

  },
};
</script>
<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>